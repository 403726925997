var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    { ref: "fbctn" },
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", customer: false, fixed: "" },
          slot: "header",
        },
        [
          _c("fb-button", {
            attrs: {
              slot: "left",
              icon: "back",
              size: "small",
              type: "primary",
            },
            on: {
              click: function ($event) {
                return _vm.$emit("back")
              },
            },
            slot: "left",
          }),
          _c("template", { slot: "customer" }, [
            _c("div", { staticClass: "header_icon" }, [
              _vm._v(_vm._s(this.id ? "修改客户" : "新增客户")),
            ]),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "added" }, [
        _c("div", { staticClass: "customer_name" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.value,
                expression: "value",
              },
            ],
            ref: "ipt",
            attrs: { placeholder: "请输入客户名称" },
            domProps: { value: _vm.value },
            on: {
              click: function ($event) {
                return _vm.bind($event)
              },
              blur: _vm.adult,
              focus: _vm.jujiao,
              input: function ($event) {
                if ($event.target.composing) return
                _vm.value = $event.target.value
              },
            },
          }),
          _c(
            "div",
            {
              staticClass: "ZiRan",
              on: {
                click: function ($event) {
                  return _vm.PersonQie($event)
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    this.switch
                      ? "自然人"
                      : this.currentPerson
                      ? "法人"
                      : "非法人"
                  ) +
                  " "
              ),
              _c("i", { staticClass: "iconfont icon-child-on" }),
            ]
          ),
          _c("div", { staticClass: "Qieswitch" }),
          _vm.FaRenOne
            ? _c("ul", { staticClass: "FaRen" }, [
                this.switch || !this.currentPerson
                  ? _c(
                      "li",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.change(0, $event)
                          },
                        },
                      },
                      [_vm._v(" 法人 ")]
                    )
                  : _vm._e(),
                this.switch || this.currentPerson
                  ? _c(
                      "li",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.change(1, $event)
                          },
                        },
                      },
                      [_vm._v(" 非法人 ")]
                    )
                  : _vm._e(),
                !this.switch
                  ? _c(
                      "li",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.change(2, $event)
                          },
                        },
                      },
                      [_vm._v("自然人")]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.search
            ? _c(
                "ul",
                { staticClass: "search" },
                [
                  _c(
                    "span",
                    [_c("loading", { attrs: { showflag: _vm.flag } })],
                    1
                  ),
                  _vm._l(_vm.person, function (item, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        attrs: { li: index },
                        on: {
                          click: function ($event) {
                            return _vm.changeval(item.PersonMessage, $event)
                          },
                        },
                      },
                      [_c("span", [_vm._v(" " + _vm._s(item.PersonMessage))])]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
        ]),
        !this.switch
          ? _c("span", [
              _c("div", { staticClass: "shenfen_id credit" }, [
                _c("p", [_vm._v("统一社会信用代码")]),
                _c(
                  "div",
                  { staticClass: "creditD" },
                  [
                    _c("van-field", {
                      attrs: { placeholder: "请输入社会信用码" },
                      model: {
                        value: _vm.legal.legal_credit,
                        callback: function ($$v) {
                          _vm.$set(_vm.legal, "legal_credit", $$v)
                        },
                        expression: "legal.legal_credit",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "legalperson" }, [
                _c("div", { staticClass: "person" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.currentPerson ? "法定代表人" : "负责人")),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "legal" },
                  [
                    _c("van-field", {
                      attrs: { placeholder: "请输入姓名" },
                      model: {
                        value: _vm.legal.legal_name,
                        callback: function ($$v) {
                          _vm.$set(_vm.legal, "legal_name", $$v)
                        },
                        expression: "legal.legal_name",
                      },
                    }),
                    _c("van-field", {
                      attrs: { placeholder: "请输入职位" },
                      model: {
                        value: _vm.legal.legal_job,
                        callback: function ($$v) {
                          _vm.$set(_vm.legal, "legal_job", $$v)
                        },
                        expression: "legal.legal_job",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "shenfen_id" }, [
                _c("p", [_vm._v("联系电话")]),
                _c(
                  "div",
                  {
                    on: {
                      input: function ($event) {
                        return _vm.phone($event)
                      },
                    },
                  },
                  [
                    _c("van-field", {
                      attrs: { placeholder: "请输入联系电话" },
                      model: {
                        value: _vm.legal.legal_phone,
                        callback: function ($$v) {
                          _vm.$set(_vm.legal, "legal_phone", $$v)
                        },
                        expression: "legal.legal_phone",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "shenfen_id address" }, [
                _c("p", [_vm._v("地址")]),
                _c(
                  "div",
                  { staticClass: "AddressVant" },
                  [
                    _c("van-field", {
                      attrs: { placeholder: "请输入地址" },
                      model: {
                        value: _vm.legal.legal_address,
                        callback: function ($$v) {
                          _vm.$set(_vm.legal, "legal_address", $$v)
                        },
                        expression: "legal.legal_address",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "sex_id infor_id",
                  on: {
                    click: function ($event) {
                      return _vm.otherin()
                    },
                  },
                },
                [
                  _c("span", [_vm._v("其他信息")]),
                  _c("i", {
                    staticClass: "iconfont icon-gengduo",
                    style: {
                      transition: "all .5s",
                      transform: this.legal.legal_show
                        ? "rotate(90deg)"
                        : "rotate(0deg)",
                    },
                  }),
                ]
              ),
            ])
          : _vm._e(),
        this.switch
          ? _c("span", [
              _c("div", { staticClass: "shenfen_id" }, [
                _c("p", [_vm._v("身份证号")]),
                _c(
                  "div",
                  {
                    staticClass: "ShenFen",
                    on: {
                      input: function ($event) {
                        return _vm.cardchange($event)
                      },
                    },
                  },
                  [
                    _c("van-field", {
                      attrs: { placeholder: "请输入身份证号" },
                      model: {
                        value: _vm.natural.natural_user,
                        callback: function ($$v) {
                          _vm.$set(_vm.natural, "natural_user", $$v)
                        },
                        expression: "natural.natural_user",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "shenfen_id" }, [
                _c("p", [_vm._v("联系电话")]),
                _c(
                  "div",
                  {
                    staticClass: "ShenFen",
                    on: {
                      input: function ($event) {
                        return _vm.phone($event)
                      },
                    },
                  },
                  [
                    _c("van-field", {
                      attrs: { placeholder: "请输入联系电话" },
                      model: {
                        value: _vm.natural.natural_phone,
                        callback: function ($$v) {
                          _vm.$set(_vm.natural, "natural_phone", $$v)
                        },
                        expression: "natural.natural_phone",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "sex_id" }, [
                _c("div", { staticClass: "sex" }, [_vm._v("性别")]),
                _c(
                  "div",
                  { staticClass: "minzu" },
                  [
                    _c(
                      "van-radio-group",
                      {
                        model: {
                          value: _vm.natural.natural_radio,
                          callback: function ($$v) {
                            _vm.$set(_vm.natural, "natural_radio", $$v)
                          },
                          expression: "natural.natural_radio",
                        },
                      },
                      [
                        _c("van-radio", { attrs: { name: "1" } }, [
                          _vm._v("男"),
                        ]),
                        _c("van-radio", { attrs: { name: "2" } }, [
                          _vm._v("女"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "minzu_id", on: { click: _vm.nation } },
                [
                  _c("div", { staticClass: "sex" }, [_vm._v("民族")]),
                  _c("div", [
                    _c("span", { staticClass: "choose" }, [
                      _vm._v(_vm._s(_vm.choose_country)),
                    ]),
                    _c("i", { staticClass: "iconfont icon-gengduo-copy" }),
                  ]),
                ]
              ),
              _c("div", { staticClass: "shenfen_id address" }, [
                _c("p", [_vm._v("住所地")]),
                _c(
                  "div",
                  { staticClass: "AddressVant" },
                  [
                    _c("van-field", {
                      attrs: { placeholder: "请输入地址" },
                      model: {
                        value: _vm.natural.natural_address,
                        callback: function ($$v) {
                          _vm.$set(_vm.natural, "natural_address", $$v)
                        },
                        expression: "natural.natural_address",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "sex_id infor_id", on: { click: _vm.otherin } },
                [
                  _c("span", [_vm._v("其他信息")]),
                  _c("i", {
                    staticClass: "iconfont icon-gengduo",
                    style: {
                      transition: "all .5s",
                      transform: this.natural.natural_show
                        ? "rotate(90deg)"
                        : "rotate(0deg)",
                    },
                  }),
                ]
              ),
            ])
          : _vm._e(),
        _c(
          "ul",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !this.switch && this.legal.legal_show,
                expression: "!this.switch && this.legal.legal_show",
              },
            ],
            staticClass: "other",
          },
          [
            _c("li", [
              _c("p", [_vm._v("文书送达地")]),
              _c(
                "div",
                { staticClass: "height" },
                [
                  _c("van-field", {
                    attrs: { placeholder: "请输入文书送达地" },
                    model: {
                      value: _vm.legal.legal_wenshu,
                      callback: function ($$v) {
                        _vm.$set(_vm.legal, "legal_wenshu", $$v)
                      },
                      expression: "legal.legal_wenshu",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("li", [
              _c("p", [_vm._v("邮政编码")]),
              _c(
                "div",
                { staticClass: "height" },
                [
                  _c("van-field", {
                    attrs: { placeholder: "请输入邮政编码" },
                    model: {
                      value: _vm.legal.legal_emaill,
                      callback: function ($$v) {
                        _vm.$set(_vm.legal, "legal_emaill", $$v)
                      },
                      expression: "legal.legal_emaill",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("li", [
              _c("p", [_vm._v("其他电话")]),
              _c(
                "div",
                {
                  staticClass: "height",
                  on: {
                    input: function ($event) {
                      return _vm.phone($event)
                    },
                  },
                },
                [
                  _c("van-field", {
                    attrs: { placeholder: "请输入其他电话" },
                    model: {
                      value: _vm.legal.legal_otherphone,
                      callback: function ($$v) {
                        _vm.$set(_vm.legal, "legal_otherphone", $$v)
                      },
                      expression: "legal.legal_otherphone",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "li",
              { staticClass: "minzu_id business", on: { click: _vm.Business } },
              [
                _c("div", { staticClass: "sex" }, [_vm._v("业务来源")]),
                _c("div", [
                  _c("span", { staticClass: "choose" }, [
                    _vm._v(_vm._s(this.legal_business)),
                  ]),
                  _c("i", { staticClass: "iconfont icon-gengduo-copy" }),
                ]),
              ]
            ),
          ]
        ),
        _c(
          "ul",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: this.switch && this.natural.natural_show,
                expression: "this.switch && this.natural.natural_show",
              },
            ],
            staticClass: "other",
          },
          [
            _c("li", [
              _c("p", [_vm._v("职业")]),
              _c(
                "div",
                { staticClass: "height" },
                [
                  _c("van-field", {
                    attrs: { placeholder: "请输入职业" },
                    model: {
                      value: _vm.natural.natural_job,
                      callback: function ($$v) {
                        _vm.$set(_vm.natural, "natural_job", $$v)
                      },
                      expression: "natural.natural_job",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("li", [
              _c("p", [_vm._v("工作单位")]),
              _c(
                "div",
                { staticClass: "height" },
                [
                  _c("van-field", {
                    attrs: { placeholder: "请输入工作单位" },
                    model: {
                      value: _vm.natural.natural_work,
                      callback: function ($$v) {
                        _vm.$set(_vm.natural, "natural_work", $$v)
                      },
                      expression: "natural.natural_work",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("li", [
              _c("p", [_vm._v("邮政编码")]),
              _c(
                "div",
                { staticClass: "height" },
                [
                  _c("van-field", {
                    attrs: { placeholder: "请输入邮政编码" },
                    model: {
                      value: _vm.natural.natural_bianma,
                      callback: function ($$v) {
                        _vm.$set(_vm.natural, "natural_bianma", $$v)
                      },
                      expression: "natural.natural_bianma",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("li", [
              _c("p", [_vm._v("其他电话")]),
              _c(
                "div",
                {
                  staticClass: "height",
                  on: {
                    input: function ($event) {
                      return _vm.phone($event)
                    },
                  },
                },
                [
                  _c("van-field", {
                    attrs: { placeholder: "请输入其他电话" },
                    model: {
                      value: _vm.natural.natural_otherphone,
                      callback: function ($$v) {
                        _vm.$set(_vm.natural, "natural_otherphone", $$v)
                      },
                      expression: "natural.natural_otherphone",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("li", [
              _c("p", [_vm._v("邮箱")]),
              _c(
                "div",
                {
                  staticClass: "height",
                  on: {
                    input: function ($event) {
                      return _vm.emaill($event)
                    },
                  },
                },
                [
                  _c("van-field", {
                    attrs: { placeholder: "请输入邮箱" },
                    model: {
                      value: _vm.natural.natural_emaill,
                      callback: function ($$v) {
                        _vm.$set(_vm.natural, "natural_emaill", $$v)
                      },
                      expression: "natural.natural_emaill",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("li", [
              _c("p", [_vm._v("QQ")]),
              _c(
                "div",
                { staticClass: "height" },
                [
                  _c("van-field", {
                    attrs: { placeholder: "请输入QQ" },
                    model: {
                      value: _vm.natural.natural_QQ,
                      callback: function ($$v) {
                        _vm.$set(_vm.natural, "natural_QQ", $$v)
                      },
                      expression: "natural.natural_QQ",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("li", [
              _c("p", [_vm._v("微信")]),
              _c(
                "div",
                { staticClass: "height" },
                [
                  _c("van-field", {
                    attrs: { placeholder: "请输入微信" },
                    model: {
                      value: _vm.natural.natural_wechat,
                      callback: function ($$v) {
                        _vm.$set(_vm.natural, "natural_wechat", $$v)
                      },
                      expression: "natural.natural_wechat",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("li", [
              _c("p", [_vm._v("诉讼文书送达地")]),
              _c(
                "div",
                { staticClass: "height" },
                [
                  _c("van-field", {
                    attrs: { placeholder: "请输入地址" },
                    model: {
                      value: _vm.natural.natural_wenshu,
                      callback: function ($$v) {
                        _vm.$set(_vm.natural, "natural_wenshu", $$v)
                      },
                      expression: "natural.natural_wenshu",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("li", [
              _c("p", [_vm._v("经常居住地")]),
              _c(
                "div",
                { staticClass: "height" },
                [
                  _c("van-field", {
                    attrs: { placeholder: "请输入地址" },
                    model: {
                      value: _vm.natural.natural_zhuzhi,
                      callback: function ($$v) {
                        _vm.$set(_vm.natural, "natural_zhuzhi", $$v)
                      },
                      expression: "natural.natural_zhuzhi",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("li", [
              _c(
                "div",
                { staticClass: "title" },
                [
                  _c("van-field", {
                    attrs: { placeholder: "输入自填项名称" },
                    model: {
                      value: _vm.natural.natural_title,
                      callback: function ($$v) {
                        _vm.$set(_vm.natural, "natural_title", $$v)
                      },
                      expression: "natural.natural_title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "height" },
                [
                  _c("van-field", {
                    attrs: { placeholder: "输入自填项内容" },
                    model: {
                      value: _vm.natural.natural_contant,
                      callback: function ($$v) {
                        _vm.$set(_vm.natural, "natural_contant", $$v)
                      },
                      expression: "natural.natural_contant",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "li",
              { staticClass: "minzu_id business", on: { click: _vm.Business } },
              [
                _c("div", { staticClass: "sex" }, [_vm._v("业务来源")]),
                _c("div", [
                  _c("span", { staticClass: "choose" }, [
                    _vm._v(_vm._s(this.natural_business)),
                  ]),
                  _c("i", { staticClass: "iconfont icon-gengduo-copy" }),
                ]),
              ]
            ),
          ]
        ),
      ]),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom", overlay: _vm.chose_sex },
          model: {
            value: _vm.chose_sex,
            callback: function ($$v) {
              _vm.chose_sex = $$v
            },
            expression: "chose_sex",
          },
        },
        [
          _c("van-picker", {
            attrs: {
              "show-toolbar": "",
              title: _vm.message,
              columns: _vm.sex,
              "item-height": _vm.pickCellheight,
            },
            on: {
              cancel: _vm.onCancel,
              confirm: _vm.onConfirm,
              change: _vm.onChange,
            },
          }),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom", overlay: _vm.chose_business },
          model: {
            value: _vm.chose_business,
            callback: function ($$v) {
              _vm.chose_business = $$v
            },
            expression: "chose_business",
          },
        },
        [
          _c("van-picker", {
            attrs: {
              "show-toolbar": "",
              title: _vm.message_business,
              columns: _vm.business,
              "item-height": _vm.pickCellheight,
            },
            on: {
              cancel: _vm.onCancel,
              confirm: _vm.onConfirm,
              change: _vm.onChange,
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "btn" }, [
        _c(
          "button",
          { attrs: { disabled: _vm.nnum }, on: { click: _vm.cancel } },
          [_vm._v(" " + _vm._s(this.id ? "删除" : "取消") + " ")]
        ),
        _c(
          "button",
          { attrs: { disabled: _vm.nnum }, on: { click: _vm.complete } },
          [_vm._v(" " + _vm._s(this.id ? "保存" : "完成") + " ")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }