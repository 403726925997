var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showflag < 2
    ? _c("div", [
        _vm.showflag === 0
          ? _c("div", { staticClass: "load" }, [
              _c("span", [_vm._v("没有更多了")]),
            ])
          : _vm.showflag === 1
          ? _c(
              "div",
              { staticClass: "load" },
              [_c("van-loading"), _c("span", [_vm._v("加载中")])],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }