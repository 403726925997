<template>
  <div v-if="showflag < 2">
    <div class="load"
         v-if="showflag === 0">
      <span>没有更多了</span>
    </div>
    <div class="load"
         v-else-if="showflag === 1">
      <van-loading />
      <span>加载中</span>
    </div>
  </div>
</template>

<script>
/*
 * 0：显示没有更多
 * 1：显示加载中
 * 2：什么都不显示
 */
export default {
  props: {
    showflag: {
      default: 1,
      type: Number
    }
  }
}
</script>

<style lang="stylus" scoped>
.load
  width 100%
  overflow hidden
  display flex
  justify-content center
  top 20px
  span
    align-self center
    color #666666
    display inline-block
</style>
