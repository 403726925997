<template>
  <fb-page ref="fbctn">
    <fb-header slot="header" :customer="false" fixed>
      <fb-button
        slot="left"
        icon="back"
        @click="$emit('back')"
        size="small"
        type="primary"
      >
      </fb-button>
      <template slot="customer">
        <div class="header_icon">{{ this.id ? '修改客户' : '新增客户' }}</div>
      </template>
    </fb-header>
    <div class="added">
      <div class="customer_name">
        <input
          v-model="value"
          placeholder="请输入客户名称"
          @click="bind($event)"
          ref="ipt"
          @blur="adult"
          @focus="jujiao"
        />

        <div class="ZiRan" @click="PersonQie($event)">
          {{ this.switch ? '自然人' : this.currentPerson ? '法人' : '非法人' }}
          <i class="iconfont icon-child-on"></i>
        </div>
        <div class="Qieswitch"></div>
        <ul class="FaRen" v-if="FaRenOne">
          <!-- lengalPerson -->
          <li
            @click="change(0, $event)"
            v-if="this.switch || !this.currentPerson"
          >
            法人
          </li>
          <li
            @click="change(1, $event)"
            v-if="this.switch || this.currentPerson"
          >
            非法人
          </li>
          <li @click="change(2, $event)" v-if="!this.switch">自然人</li>
        </ul>
        <ul class="search" v-if="search">
          <span>
            <loading :showflag="flag"></loading>
          </span>
          <li
            v-for="(item, index) in person"
            :key="index"
            :li="index"
            @click="changeval(item.PersonMessage, $event)"
          >
            <span> {{ item.PersonMessage }}</span>
          </li>
        </ul>
      </div>
      <!-- 法人 -->
      <span v-if="!this.switch">
        <div class="shenfen_id credit">
          <p>统一社会信用代码</p>

          <div class="creditD">
            <van-field
              v-model="legal.legal_credit"
              placeholder="请输入社会信用码"
            />
          </div>
        </div>
        <div class="legalperson">
          <div class="person">
            <span>{{ currentPerson ? '法定代表人' : '负责人' }}</span>
          </div>
          <div class="legal">
            <van-field v-model="legal.legal_name" placeholder="请输入姓名" />
            <van-field v-model="legal.legal_job" placeholder="请输入职位" />
          </div>
        </div>
        <div class="shenfen_id">
          <p>联系电话</p>
          <div @input="phone($event)">
            <van-field
              v-model="legal.legal_phone"
              placeholder="请输入联系电话"
            />
          </div>
        </div>

        <div class="shenfen_id address">
          <p>地址</p>
          <div class="AddressVant">
            <van-field v-model="legal.legal_address" placeholder="请输入地址" />
          </div>
        </div>
        <div class="sex_id infor_id" @click="otherin()">
          <span>其他信息</span>
          <i
            class="iconfont icon-gengduo"
            :style="{
              transition: 'all .5s',
              transform: this.legal.legal_show
                ? 'rotate(90deg)'
                : 'rotate(0deg)'
            }"
          ></i>
        </div>
      </span>
      <!-- 自然人 -->
      <span v-if="this.switch">
        <div class="shenfen_id">
          <p>身份证号</p>
          <div @input="cardchange($event)" class="ShenFen">
            <van-field
              v-model="natural.natural_user"
              placeholder="请输入身份证号"
            />
          </div>
        </div>
        <div class="shenfen_id">
          <p>联系电话</p>
          <div @input="phone($event)" class="ShenFen">
            <van-field
              v-model="natural.natural_phone"
              placeholder="请输入联系电话"
            />
          </div>
        </div>
        <div class="sex_id">
          <div class="sex">性别</div>
          <div class="minzu">
            <van-radio-group v-model="natural.natural_radio">
              <van-radio name="1">男</van-radio>
              <van-radio name="2">女</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="minzu_id" @click="nation">
          <div class="sex">民族</div>
          <div>
            <span class="choose">{{ choose_country }}</span>
            <i class="iconfont icon-gengduo-copy"></i>
          </div>
        </div>
        <div class="shenfen_id address">
          <p>住所地</p>
          <div class="AddressVant">
            <van-field
              v-model="natural.natural_address"
              placeholder="请输入地址"
            />
          </div>
        </div>
        <div class="sex_id infor_id" @click="otherin">
          <span>其他信息</span>
          <i
            class="iconfont icon-gengduo"
            :style="{
              transition: 'all .5s',
              transform: this.natural.natural_show
                ? 'rotate(90deg)'
                : 'rotate(0deg)'
            }"
          ></i>
        </div>
      </span>
      <!-- 法人其他信息 -->
      <ul class="other" v-show="!this.switch && this.legal.legal_show">
        <li>
          <p>文书送达地</p>
          <div class="height">
            <van-field
              v-model="legal.legal_wenshu"
              placeholder="请输入文书送达地"
            />
          </div>
        </li>

        <li>
          <p>邮政编码</p>
          <div class="height">
            <van-field
              v-model="legal.legal_emaill"
              placeholder="请输入邮政编码"
            />
          </div>
        </li>
        <li>
          <p>其他电话</p>
          <div class="height" @input="phone($event)">
            <van-field
              v-model="legal.legal_otherphone"
              placeholder="请输入其他电话"
            />
          </div>
        </li>
        <li class="minzu_id business" @click="Business">
          <div class="sex">业务来源</div>
          <div>
            <span class="choose">{{ this.legal_business }}</span>
            <i class="iconfont icon-gengduo-copy"></i>
          </div>
        </li>
      </ul>
      <!-- 自然人其他信息 -->
      <ul class="other" v-show="this.switch && this.natural.natural_show">
        <li>
          <p>职业</p>
          <div class="height">
            <van-field v-model="natural.natural_job" placeholder="请输入职业" />
          </div>
        </li>
        <li>
          <p>工作单位</p>
          <div class="height">
            <van-field
              v-model="natural.natural_work"
              placeholder="请输入工作单位"
            />
          </div>
        </li>
        <li>
          <p>邮政编码</p>
          <div class="height">
            <van-field
              v-model="natural.natural_bianma"
              placeholder="请输入邮政编码"
            />
          </div>
        </li>
        <li>
          <p>其他电话</p>
          <div class="height" @input="phone($event)">
            <van-field
              v-model="natural.natural_otherphone"
              placeholder="请输入其他电话"
            />
          </div>
        </li>
        <li>
          <p>邮箱</p>
          <div class="height" @input="emaill($event)">
            <van-field
              v-model="natural.natural_emaill"
              placeholder="请输入邮箱"
            />
          </div>
        </li>
        <li>
          <p>QQ</p>
          <div class="height">
            <van-field v-model="natural.natural_QQ" placeholder="请输入QQ" />
          </div>
        </li>
        <li>
          <p>微信</p>
          <div class="height">
            <van-field
              v-model="natural.natural_wechat"
              placeholder="请输入微信"
            />
          </div>
        </li>
        <li>
          <p>诉讼文书送达地</p>
          <div class="height">
            <van-field
              v-model="natural.natural_wenshu"
              placeholder="请输入地址"
            />
          </div>
        </li>
        <li>
          <p>经常居住地</p>
          <div class="height">
            <van-field
              v-model="natural.natural_zhuzhi"
              placeholder="请输入地址"
            />
          </div>
        </li>
        <li>
          <div class="title">
            <van-field
              v-model="natural.natural_title"
              placeholder="输入自填项名称"
            />
          </div>
          <div class="height">
            <van-field
              v-model="natural.natural_contant"
              placeholder="输入自填项内容"
            />
          </div>
        </li>
        <li class="minzu_id business" @click="Business">
          <div class="sex">业务来源</div>
          <div>
            <span class="choose">{{ this.natural_business }}</span>
            <i class="iconfont icon-gengduo-copy"></i>
          </div>
        </li>
      </ul>
    </div>
    <van-popup v-model="chose_sex" position="bottom" :overlay="chose_sex">
      <van-picker
        show-toolbar
        :title="message"
        :columns="sex"
        @cancel="onCancel"
        @confirm="onConfirm"
        @change="onChange"
        :item-height="pickCellheight"
      />
    </van-popup>
    <van-popup
      v-model="chose_business"
      position="bottom"
      :overlay="chose_business"
    >
      <van-picker
        show-toolbar
        :title="message_business"
        :columns="business"
        @cancel="onCancel"
        @confirm="onConfirm"
        @change="onChange"
        :item-height="pickCellheight"
      />
    </van-popup>
    <div class="btn">
      <button @click="cancel" :disabled="nnum">
        {{ this.id ? '删除' : '取消' }}
      </button>
      <button @click="complete" :disabled="nnum">
        {{ this.id ? '保存' : '完成' }}
      </button>
    </div>
  </fb-page>
</template>
<script>
import { caseBase } from '~api-config'
import { params } from './nation.js'
import { setTimeout } from 'timers'
import loading from '@/pages/Search/compoments/loading'
export default {
  components: { loading },
  props: ['id'],
  data() {
    return {
      flag: 2,
      FaRenOne: false,
      chose_sex: false,
      sex: params.nation,
      choose_country: '汉族',
      value: '',
      other: false,
      lengal: false,
      message: '请选择民族',
      search: false,
      naturalperson: true,
      Person: false,
      currentPerson: true,
      init: true,
      chose_business: false,
      business: [
        '请选择',
        '上门服务',
        '电话',
        '顾问电话',
        '介绍',
        '网络',
        '其他'
      ],
      message_business: '请选择上门业务来源',
      natural_business: '请选择',
      legal_business: '请选择',
      nnum: false,
      BodyHeight: 0,
      isBkur: false,
      // 法人信息
      legal: {
        legal_credit: '',
        legal_name: '',
        legal_job: '',
        legal_address: '',
        legal_phone: '',
        legal_otherphone: '',
        legal_emaill: '',
        legal_wenshu: '',
        legal_show: false
      },
      // 自然人信息
      natural: {
        natural_user: '',
        natural_phone: '',
        natural_address: '',
        natural_job: '',
        natural_work: '',
        natural_emaill: '',
        natural_bianma: '',
        natural_otherphone: '',
        natural_QQ: '',
        natural_wechat: '',
        natural_contant: '',
        natural_radio: '1',
        natural_show: false,
        natural_title: ''
      },
      switch: true,
      person: [],
      prevent: false,
      inputHgt: 0,
      req: null
    }
  },
  methods: {
    jujiao() {
      this.search = true
    },
    adult() {
      setTimeout(() => {
        this.search = false
      }, 300)
    },
    Business() {
      this.chose_business = !this.chose_business
    },
    change(type, e) {
      if (type === 0) {
        // 法人
        this.switch = false
        this.currentPerson = true
      } else if (type === 1) {
        // 负责人
        this.switch = false
        this.currentPerson = false
      } else {
        // 自然人
        this.switch = true
      }
      this.FaRenOne = false
    },
    focusHandler(e) {
      // let fn = null
      if (
        document.activeElement.tagName === 'INPUT' &&
        document.activeElement.type === 'text'
      ) {
        // this.isBkur = true
        // let Top = 0
        setTimeout(() => {
          //   fn = () => {
          //     this.isBkur = false
          //     document.body.onresize = null
          //   }
          //   document.body.onresize = fn
          //   let hgt = document.body.offsetHeight
          //   if (e.pageY < hgt) {
          //     return false
          //   }
          //   Top = this.$refs.fbctn.$el.scrollTop + (this.BodyHeight - hgt)

          // this.$refs.fbctn.$el.scrollTop = Top
          document.activeElement.scrollIntoView({ behavior: 'smooth' })
        }, 300)
      }

      // document.activeElement.scrollIntoView({ behavior: 'smooth' })
    },
    bind() {
      // document.body.addEventListener('click', this.hideInput, false)
    },
    PersonName() {
      this.Person = !this.Person
    },
    PersonQie(e) {
      this.FaRenOne = !this.FaRenOne
      e.stopPropagation()
    },
    cardchange(e) {
      if (!this.checkForm('cardId', e.target.value)) {
        e.target.style.color = 'red'
      } else {
        e.target.style.color = '#666'
        this.$axios
          .get(
            `${caseBase}/new-design/adminstrative/select?idNum=${
              e.target.value
            }`
          )
          .then(res => {
            const data = res.data.data
            this.natural.natural_zhuzhi = `${data.regionBean.province}${
              data.regionBean.city
            }${data.regionBean.district}`
            this.natural.natural_address = `${data.regionBean.province}${
              data.regionBean.city
            }${data.regionBean.district}`
            this.natural.natural_bianma = data.postCode
            this.natural.natural_radio = data.gender === '男' ? '1' : '2'
          })
      }
    },
    phone(e) {
      if (!this.checkForm('phone', e.target.value)) {
        e.target.style.color = 'red'
      } else {
        e.target.style.color = '#666'
      }
    },
    emaill(e) {
      if (!this.checkForm('email', e.target.value)) {
        e.target.style.color = 'red'
      } else {
        e.target.style.color = '#666'
      }
    },
    checkForm(type, val) {
      // type->调用所需要的方法
      const paramsobj = {
        email: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
        phone: /^[1][3,4,5,7,8,9][0-9]{9}$/,
        cardId: /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|31)|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/,
        QQ: /^[0-9]*$/
      }
      return paramsobj[type].test(val)
    },
    nation() {
      this.chose_sex = true
    },
    onChange(picker, value, index) {
      if (this.chose_business) {
        this.message_business = value
      } else {
        this.message = value
      }
    },
    changeval(text, e) {
      this.prevent = true
      this.value = text
      this.search = false
      this.switch = false
      this.$axios
        .get(`${caseBase}/management/business?name=${text}`)
        .then(res => {
          // 社会信用码 地址 职位查询
          this.$axios
            .get(
              `${caseBase}/management/getInfoByKey?companyName=${
                res.data[0].companyName
              }`
            )
            .then(res => {
              this.$set(this.legal, 'legal_credit', res.data.creditCode) // 社会信用码
              this.$set(this.legal, 'legal_address', res.data.address) // 地址
              this.$set(this.legal, 'legal_job', res.data.legalPersonPosition) // 职位
              this.$set(this.legal, 'legal_name', res.data.faRen)
            })
            .catch(err => {
              Promise.reject(err)
            })
          // 邮政编码查询
          this.$axios
            .post(`${caseBase}/new-design/adminstrative/get`, {
              address: res.data[0].address
            })
            .then(res => {
              this.$set(
                this.legal,
                'legal_emaill',
                res.data.data.vos[res.data.data.vos.length - 1].postCode
              )
                .then(res => {
                  this.$set(this.legal, 'legal_credit', res.data.creditCode) // 社会信用码
                  this.$set(this.legal, 'legal_address', res.data.address) // 地址
                  this.$set(
                    this.legal,
                    'legal_job',
                    res.data.legalPersonPosition
                  ) // 职位
                  this.$set(this.legal, 'legal_name', res.data.faRen)
                })
                .catch(err => {
                  Promise.reject(err)
                })
              // 邮政编码查询
              this.$axios
                .post(`${caseBase}/new-design/adminstrative/get`, {
                  address: res.data[0].address
                })
                .then(res => {
                  this.$set(
                    this.legal,
                    'legal_emaill',
                    res.data.data.vos[res.data.data.vos.length - 1].postCode
                  )
                })
                .catch(err => {
                  Promise.reject(err)
                })
            })
        })
    },
    onCancel() {
      if (this.chose_business) {
        this.chose_business = false
      } else {
        this.chose_sex = false
      }
    },
    onConfirm(picker, value, index) {
      if (this.chose_business && this.switch) {
        this.natural_business = picker
        this.chose_business = false
      } else if (this.chose_business && !this.switch) {
        this.legal_business = picker
        this.chose_business = false
      } else {
        this.choose_country = picker
        this.chose_sex = false
      }
    },
    otherin() {
      if (this.switch) {
        this.natural.natural_show = !this.natural.natural_show
      } else {
        this.legal.legal_show = !this.legal.legal_show
      }
    },
    cancel() {
      if (this.id) {
        this.$dialog
          .confirm({
            message: '是否删除该用户?'
          })
          .then(() => {
            this.$axios
              .delete(
                `${caseBase}/lts/customer/deleteCustomer?customerId=${this.id}`
              )
              .then(res => {
                if (res.data.code === '200') {
                  this.$notify({
                    message: '删除成功',
                    duration: 500,
                    background: '#1989fa'
                  })
                  this.$emit('back')
                }
              })
              .catch(err => {
                Promise.reject(err)
                return false
              })
          })
      } else {
        this.$emit('back')
      }
    },
    hideInput(e) {
      if (this.id) {
        return false
      }
      // 当为修改页面时,阻止它隐藏
      if (e.path[0] === this.$refs.ipt) {
        return false
      }
      this.search = false
      if (this.person.length === 0) {
        for (const item in this.legal) {
          if (item === 'legal_show') {
            continue
          }
          this.legal[item] = ''
        }
        // document.body.removeEventListener('click', this.hideInput)
        return false
      }
      for (let i = 0; i < this.person.length; i++) {
        if (this.person[i].PersonMessage === this.value) {
          // document.body.removeEventListener('click', this.hideInput)
          return false
        }
        if (i === this.person.length - 1) {
          for (const item in this.legal) {
            if (item === 'legal_show') {
              continue
            }
            this.legal[item] = ''
          }
        }
      }
      // document.body.removeEventListener('click', this.hideInput)
    },
    valchange(val) {
      if (this.init) {
        this.init = false
        return false
      }
      if (val.trim().length >= 4) {
        this.switch = false
        this.currentPerson = true
        this.Search(val)
      } else if (val.length >= 2) {
        this.Search(val)
      } else {
        // this.search = false
        this.switch = true
        this.naturalperson = true
        return false
      }
    },
    Search(val) {
      this.flag = 1
      this.person = []
      // 搜索法人非法人列表
      this.$axios
        .get(`${caseBase}/management/business?name=${val}`, {
          cancelToken: this.req.token
        })
        .then(res => {
          if (res.data.length === 0) {
            this.flag = 0
            return
          }
          this.flag = 2
          const search = res.data
          if (search) {
            search.forEach(item => {
              // this.search = true
              this.person.push({
                PersonMessage: item.companyName
              })
              //
              if (this.prevent) {
                this.prevent = false
              } else {
                this.search = true
              }

              if (item.regType === '其他') {
                // 如果redtype=其他，则为非法人单位
                this.currentPerson = false
              } else {
                this.currentPerson = true
              }
            })
          }
        })
        .catch(() => {})
    },
    complete() {
      const params = {}
      if (this.value.trim().length === 0) {
        this.$notify('请输入客户名称')
        return
      } else if (this.value.trim().length < 2) {
        this.$notify('请输入正确的客户名称')
        return
      }
      params.name = this.value
      for (const item in this.natural) {
        if (String(this.natural[item]) === 'null') {
          this.natural[item] = ''
        }
      }
      if (this.switch) {
        if (
          this.natural.natural_user.length > 0 &&
          !this.checkForm('cardId', this.natural.natural_user)
        ) {
          this.$notify('请输入正确的身份证号')
          return false
        } else if (
          this.natural.natural_phone.length > 0 &&
          !this.checkForm('phone', this.natural.natural_phone)
        ) {
          this.$notify('请输入正确的手机号')
          return false
        } else if (
          this.natural.natural_emaill.length > 0 &&
          !this.checkForm('email', this.natural.natural_emaill)
        ) {
          this.$notify('请输入正确的邮箱地址')
          return false
        } else if (
          this.natural.natural_otherphone.length > 0 &&
          !this.checkForm('phone', this.natural.natural_otherphone)
        ) {
          this.$notify('请输入正确的其他联系人手机号')
          return false
        }
        params.category = 'NATURAL_PERSON'
        params.nation = this.choose_country
        let source = 'UNKNOWN'
        switch (this.natural_business) {
          case '上门服务':
            source = 'DOOR_TO_DOOR_SERVICE'
            break
          case '介绍':
            source = 'INTRODUCE'
            break
          case '网络':
            source = 'NETWORK'
            break
          case '顾问单位':
            source = 'CONSULTANT_UNIT'
            break
          case '电话':
            source = 'TELEPHONE'
            break
          case '其他':
            source = 'OTHER'
            break
        }
        params.source = source
        // this.natural_business === '上门服务'
        //   ? 'DOOR_TO_DOOR_SERVICE'
        //   : this.natural_business === '介绍'
        //     ? 'INTRODUCE'
        //     : this.natural_business === '网络'
        //       ? 'NETWORK'
        //       : this.natural_business === '顾问单位'
        //         ? 'CONSULTANT_UNIT'
        //         : this.natural_business === '电话'
        //           ? 'TELEPHONE'
        //           : this.natural_business === '其他'
        //             ? 'OTHER'
        //             : 'UNKNOWN'
        for (const item in this.natural) {
          switch (item) {
            case 'natural_user': // 身份证
              params.idNumber = this.natural.natural_user
              break
            case 'natural_phone': // 手机号
              params.phone = this.natural.natural_phone
              break
            case 'natural_radio': // 性别
              params.sex = this.natural.natural_radio
              break
            case 'natural_address': // 住所
              params.address = this.natural.natural_address
              break
            case 'natural_job': // 职业
              params.profession = this.natural.natural_job
              break
            case 'natural_work': // 工作单位
              params.workOffice = this.natural.natural_work
              break
            case 'natural_bianma': // 邮政编码
              params.postalCode = this.natural.natural_bianma
              break
            case 'natural_otherphone': // 其他电话
              params.otherPhone = this.natural.natural_otherphone
              break
            case 'natural_emaill': // 邮箱
              params.email = this.natural.natural_emaill
              break
            case 'natural_QQ': // qq
              params.qq = this.natural.natural_QQ
              break
            case 'natural_wechat': // 微信
              params.weChat = this.natural.natural_wechat
              break
            case 'natural_wenshu': // 文书送达地
              params.documentDelivery = this.natural.natural_wenshu
              break
            case 'natural_zhuzhi': // 经常居住地
              params.longResidence = this.natural.natural_zhuzhi
              break
            case 'natural_contant': // 自填项内容
              params.selfColumnValue = this.natural.natural_contant
              break
            case 'natural_title': // 自填项名称
              params.selfColumnName = this.natural.natural_title
          }
        }
      } else {
        for (const item in this.legal) {
          if (String(this.legal[item]) === 'null') {
            this.legal[item] = ''
          }
        }
        if (
          this.legal.legal_phone.length > 0 &&
          !this.checkForm('phone', this.legal.legal_phone)
        ) {
          this.$notify('请输入正确的手机号')
          return false
        } else if (
          this.legal.legal_otherphone.length > 0 &&
          !this.checkForm('phone', this.legal.legal_otherphone)
        ) {
          this.$notify('请输入正确的其他联系人手机号')
          return false
        }
        params.category = this.currentPerson
          ? 'LEGAL_ENTITY'
          : 'NON_LEGAL_ENTITY'
        let source = 'UNKNOWN'
        switch (this.natural_business) {
          case '上门服务':
            source = 'DOOR_TO_DOOR_SERVICE'
            break
          case '介绍':
            source = 'INTRODUCE'
            break
          case '网络':
            source = 'NETWORK'
            break
          case '顾问单位':
            source = 'CONSULTANT_UNIT'
            break
          case '电话':
            source = 'TELEPHONE'
            break
          case '其他':
            source = 'OTHER'
            break
        }
        params.source = source
        // this.legal_business === '上门服务'
        //   ? 'DOOR_TO_DOOR_SERVICE'
        //   : this.legal_business === '介绍'
        //   ? 'INTRODUCE'
        //   : this.legal_business === '网络'
        //   ? 'NETWORK'
        //   : this.legal_business === '顾问单位'
        //   ? 'CONSULTANT_UNIT'
        //   : this.legal_business === '电话'
        //   ? 'TELEPHONE'
        //   : this.legal_business === '其他'
        //   ? 'OTHER'
        //   : 'UNKNOWN'
        for (const item in this.legal) {
          switch (item) {
            case 'legal_credit': // 统一社会信用代码
              params.idNumber = this.legal.legal_credit
              break
            case 'legal_name': // 法定代表人
              params.manager = this.legal.legal_name
              break
            case 'legal_job': // 职位
              params.position = this.legal.legal_job
              break
            case 'legal_phone': // 联系电话
              params.phone = this.legal.legal_phone
              break
            case 'legal_address': // 地址
              params.address = this.legal.legal_address
              break
            case 'legal_wenshu': // 文书送达地
              params.documentDelivery = this.legal.legal_wenshu
              break
            case 'legal_emaill': // 邮政编码
              params.postalCode = this.legal.legal_emaill
              break
            case 'legal_otherphone': // 其他电话
              params.otherPhone = this.legal.legal_otherphone
              break
          }
        }
      }

      params.customerId = this.id
      this.nnum = true
      this.$axios[this.id ? 'put' : 'post'](
        this.id
          ? `${caseBase}/lts/customer/updateCustomer`
          : `${caseBase}/lts/customer/create`,
        params
      ).then(res => {
        if (res.data.code === '200') {
          this.$notify({
            message: (this.id ? '保存' : '新建') + '成功',
            background: '#67C23A'
          })
          this.$emit('back')
        } else {
          this.$notify(res.data.message)
        }
        this.nnum = false
      })
    }
  },
  computed: {
    pickCellheight() {
      const dpr = document.documentElement.getAttribute('data-dpr') || 1
      return dpr * 44
    }
  },
  mounted() {
    this.BodyHeight = document.body.offsetHeight
    if (this.id) {
      this.$axios
        .get(`${caseBase}/lts/customer/queryCustomer?customerId=${this.id}`)
        .then(res => {
          const customerid = res.data.data.customer
          this.value = customerid.name
          if (customerid.category === 'NATURAL_PERSON') {
            this.switch = true
            this.natural.natural_user = customerid.idNumber
            this.natural.natural_phone = customerid.phone
            this.natural.natural_radio = customerid.sex === 'FEMALE' ? '2' : '1'
            this.choose_country = customerid.nation
            this.natural.natural_address = customerid.address
            this.natural.natural_job = customerid.profession
            this.natural.natural_work = customerid.workOffice
            this.natural.natural_bianma = customerid.postalCode
            this.natural.natural_otherphone = customerid.otherPhone
            this.natural.natural_emaill = customerid.email
            this.natural.natural_QQ = customerid.qq
            this.natural.natural_wechat = customerid.weChat
            this.natural.natural_wenshu = customerid.documentDelivery
            this.natural.natural_contant = customerid.selfColumnValue
            this.natural.natural_zhuzhi = customerid.longResidence
            this.natural.natural_title = customerid.selfColumnName
            let naturalBbusiness = '请选择'
            switch (customerid.source) {
              case 'DOOR_TO_DOOR_SERVICE':
                naturalBbusiness = '上门服务'
                break
              case 'INTRODUCE':
                naturalBbusiness = '介绍'
                break
              case 'NETWORK':
                naturalBbusiness = '网络'
                break
              case 'CONSULTANT_UNIT':
                naturalBbusiness = '顾问单位'
                break
              case 'TELEPHONE':
                naturalBbusiness = '电话'
                break
              case 'OTHER':
                naturalBbusiness = '其他'
                break
            }
            this.natural_business = naturalBbusiness
            // customerid.source === 'DOOR_TO_DOOR_SERVICE'
            //   ? '上门服务'
            //   : customerid.source === 'INTRODUCE'
            //   ? '介绍'
            //   : customerid.source === 'NETWORK'
            //   ? '网络'
            //   : customerid.source === 'CONSULTANT_UNIT'
            //   ? '顾问单位'
            //   : customerid.source === 'TELEPHONE'
            //   ? '电话'
            //   : customerid.source === 'OTHER'
            //   ? '其他'
            //   : '请选择'
          } else {
            this.switch = false
            if (customerid.category === 'LEGAL_ENTITY') {
              this.currentPerson = true
            } else {
              this.currentPerson = false
            }
            this.legal.legal_otherphone = customerid.otherPhone
            this.legal.legal_credit = customerid.idNumber
            this.legal.legal_name = customerid.manager
            this.legal.legal_job = customerid.position
            this.legal.legal_phone = customerid.phone
            this.legal.legal_address = customerid.address
            this.legal.legal_wenshu = customerid.documentDelivery
            this.legal.legal_emaill = customerid.postalCode
            let naturalBbusiness = '请选择'
            switch (customerid.source) {
              case 'DOOR_TO_DOOR_SERVICE':
                naturalBbusiness = '上门服务'
                break
              case 'INTRODUCE':
                naturalBbusiness = '介绍'
                break
              case 'NETWORK':
                naturalBbusiness = '网络'
                break
              case 'CONSULTANT_UNIT':
                naturalBbusiness = '顾问单位'
                break
              case 'TELEPHONE':
                naturalBbusiness = '电话'
                break
              case 'OTHER':
                naturalBbusiness = '其他'
                break
            }
            this.legal_business = naturalBbusiness
            // customerid.source === 'DOOR_TO_DOOR_SERVICE'
            //   ? '上门服务'
            //   : customerid.source === 'INTRODUCE'
            //   ? '介绍'
            //   : customerid.source === 'NETWORK'
            //   ? '网络'
            //   : customerid.source === 'CONSULTANT_UNIT'
            //   ? '顾问单位'
            //   : customerid.source === 'TELEPHONE'
            //   ? '电话'
            //   : customerid.source === 'OTHER'
            //   ? '其他'
            //   : '请选择'
          }
        })
        .catch(err => {
          this.$notify('查询客户失败')
          Promise.reject(err)
        })
    }
    // document.body.addEventListener('click', this.hideInput, false)
    document.body.addEventListener('click', this.focusHandler, true)
    if (this.id) {
      this.init = true
    } else {
      this.init = false
    }
    document.querySelector('.minzu .van-radio-group').style.display = 'flex'
    document.querySelector(
      '.minzu .van-radio-group'
    ).firstElementChild.style.alignSelf = 'center'
    document.querySelector(
      '.minzu .van-radio-group'
    ).lastElementChild.style.alignSelf = 'center'
  },
  destroyed() {
    // document.body.removeEventListener('click', this.hideInput)
    document.body.removeEventListener('click', this.focusHandler)
  },
  watch: {
    value(val) {
      clearTimeout(this.t)
      if (this.req) {
        this.req.cancel('接口请求被取消')
      }
      this.t = setTimeout(() => {
        this.$nextTick(() => {
          this.req = this.$axios.CancelToken.source()
          this.valchange(val)
        })
      }, 300)
    }
  }
}
</script>
<style lang="stylus">
.fb-page
  position relative
  overflow auto
  padding-bottom 80px
  .header_icon
    width 100%
    text-align left
  .btn
    width 100%
    height 50px
    position fixed
    left 0px
    display flex
    bottom 0px
    justify-content space-between
    line-height 50px
    font-size 16px
    color #ffffff
    text-align center
    button:nth-child(1)
      width 50%
      background #b7b8b9
      display inline-block
      outline none
      border none
    button:nth-child(2)
      width 50%
      background rgba(75, 158, 251, 1)
      display inline-block
      outline none
      border none
  .added
    width 90%
    margin 0px auto
    margin-top 10px
    font-size 15px
    color #666666
    .customer_name
      width 100%
      height 40px
      border 1px solid #F3F4F7
      display flex
      position relative
      .ZiRan
        width 32%
        text-align center
        position absolute
        right 0px
        z-index 1000
      .FaRen
        width 31%
        height 40px
        line-height 40px
        border 1px solid #F3F4F7
        text-align center
        position absolute
        bottom -42px
        right 0px
        z-index 1000
        background #ffffff
        li:nth-child(1)
          border none
        li
          height 40px
          line-height 40px
          width 100%
          background #ffffff
          border 1px solid #F3F4F7
      .Qieswitch
        width 1px
        height 15px
        background rgba(204, 204, 204, 1)
        position absolute
        top 10%
        right 30%
      .search
        width 100%
        height 228px
        position absolute
        bottom -230px
        left 0px
        margin 0px
        z-index 999
        background rgba(255, 255, 255, 1)
        box-shadow 0px 4px 8px 1px rgba(190, 190, 190, 0.64)
        border-radius 5px
        overflow-y auto
        li
          width 95%
          line-height 35px
          border-bottom 1px solid #EEEEEE
          margin 0px auto
      input
        width 67%
        border none
        outline none
        border-radius 3px
        text-indent 10px
        height 95%
      div
        margin-top 7px
        margin-left 10px
        color #569AF9
        font-size 15px
    .legalperson
      border-bottom 1px solid #F3F4F7
      display flex
      justify-content space-between
      height 44px
      line-height 44px
      position relative
      .FuZe
        border 1px solid #F3F4F7
        position absolute
        top 46px
        left 0px
        z-index 999
        background #ffffff
        width 30%
      .person
        width 50%
        border none
      .legal
        display flex
        .van-field__control
          text-align right
        .van-field::after
          border none
    .address
      .AddressVant
        width 85%
    .credit
      p
        width 40%
      .creditD
        width 60%
    .shenfen_id
      width 100%
      height 50px
      line-height 50px
      border-bottom 1px solid #F3F4F7
      display flex
      justify-content space-between
      p
        width 50%
      .ShenFen
        width 60%
      .van-field__control
        text-align right
    .business
      .icon-gengduo-copy
        margin-right 18px
    .minzu_id
      width 100%
      height 50px
      line-height 50px
      border-bottom 1px solid #F3F4F7
      display flex
      justify-content space-between
      color #666666
      .sex
        width 50%
      .choose
        margin-right 5px
      .icon-gengduo-copy
        vertical-align 3px
    .sex_id
      width 100%
      height 50px
      line-height 50px
      border-bottom 1px solid #F3F4F7
      display flex
      justify-content space-between
      .sex
        width 50%
        height 100%
      .minzu
        .van-radio-group
          display flex
          .van-radio
            margin-left 5px
            align-self center
            margin-top 15px
            i
              vertical-align 23px
            .van-radio__label
              margin-left 4px
    .infor_id
      span
        color #4B9EFB
      .icon-gengduo
        color #999999
    .other
      overflow auto
      li
        width 100%
        height 47px
        line-height 44px
        border-bottom 1px solid #f2f2f2
        display flex
        justify-content space-between
        .title
          .van-cell
            padding-left 0px
        .height
          overflow hidden
          width 50%
          .van-field__control
            text-align right
        p
          width 50%
</style>
